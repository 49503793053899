<template>
  <div id="app" ref="app">
    <div class="cursor">
      <div class="circle" ref="circle"></div>
      <div class="regCursor" ref="regCursor">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="39" width="39" fill="none" stroke-miterlimit="10" viewBox="0 0 50 50" style="enable-background:new 0 0 45.76 45.76;" xml:space="preserve">
          <circle class="st0" cx="22.88" cy="22.88" r="16"/><line class="st1" x1="22.88" y1="0" x2="22.88" y2="45.76"/><line class="st1" x1="0" y1="22.88" x2="45.76" y2="22.88"/>
        </svg>
      </div>
      <div class="regCursorC" ref="regCursorC">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="39" width="39" fill="none" stroke="cyan" stroke-miterlimit="10" viewBox="0 0 50 50" style="enable-background:new 0 0 45.76 45.76;" xml:space="preserve">
          <circle class="st0" cx="22.88" cy="22.88" r="16"/><line class="st1" x1="22.88" y1="0" x2="22.88" y2="45.76"/><line class="st1" x1="0" y1="22.88" x2="45.76" y2="22.88"/>
        </svg>
      </div>
      <div class="regCursorM" ref="regCursorM">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="39" width="39" fill="none" stroke="magenta" stroke-miterlimit="10" viewBox="0 0 50 50" style="enable-background:new 0 0 45.76 45.76;" xml:space="preserve">
          <circle class="st0" cx="22.88" cy="22.88" r="16"/><line class="st1" x1="22.88" y1="0" x2="22.88" y2="45.76"/><line class="st1" x1="0" y1="22.88" x2="45.76" y2="22.88"/>
        </svg>
      </div>
      <div class="regCursorY" ref="regCursorY">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="39" width="39" fill="none" stroke="yellow" stroke-miterlimit="10" viewBox="0 0 50 50" style="enable-background:new 0 0 45.76 45.76;" xml:space="preserve">
          <circle class="st0" cx="22.88" cy="22.88" r="16"/><line class="st1" x1="22.88" y1="0" x2="22.88" y2="45.76"/><line class="st1" x1="0" y1="22.88" x2="45.76" y2="22.88"/>
        </svg>
      </div>
    </div>

    <div id="header" ref="header">
      <div id="logo" ref="logo">
        <router-link to="/" title="Yamen Albadin" @click.native="hideList"><img src="../src/assets/imgs/Logo.svg" alt=""></router-link>
        <router-link id="backBtn" to="" v-if="back" @click.native="goBack" >
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="37.4px" height="32.2px" viewBox="0 0 37.4 32.2" style="enable-background:new 0 0 37.4 32.2;" xml:space="preserve">
            <polygon class="st0" points="0.7,16.1 5.8,21.2 16.1,31.5 21.3,26.3 14.6,19.7 36.9,19.7 36.9,12.5 14.6,12.5 21.3,5.9 16.1,0.7 "/>
          </svg>
        </router-link>
      </div>
      <div id="nav">
        <ul>
          <li><router-link to="" @click.native="showList">Projects list</router-link></li>
          <li><router-link to="/info" @click.native="hideList">info</router-link></li>
        </ul>
      </div><!--end nav-->
    </div>

    <transition :name="clipReveal">
      <projectsList v-if="projList" v-on:hideProjectList="showList"/>
    </transition>
    
    <div class="viewport">
      <transition :name="slide" v-on:after-leave="smoothScroll" >
        <router-view ref="content" :hideCursor="hideCursor"></router-view>
      </transition>
    </div>
    <div id="socialMedia" v-if="!isMobile">
      <a href="https://www.instagram.com/y_xyzgfx/" target="_blank">
        <img id="instagramIcon" src="../src/assets/imgs/Instagram_Icon.svg" alt="Instagram"/>
      </a>
      <a href="https://www.instagram.com/yamenalbadin/" target="_blank">
        <img id="instagramIcon" src="../src/assets/imgs/Instagram_Icon.svg" alt="Instagram"/>
      </a>
      
    </div>

  </div>
</template>

<script>
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'App', 
  
  data(){
    return{
      slide: "slideLeft",
      projList: false,
      clipReveal: "clipReveal",
      
    }
  },
  mounted(){
    console.log("%c            Created with 💛 by Yamen Albadin            ", "font-size: 0.8rem; color: white; line-height: 4rem; background-color:darkblue")
    if(this.isMobile){
      this.$refs.circle.style.display = "none"
      this.$refs.regCursor.style.display = "none"
      this.$refs.regCursorC.style.display = "none"
      this.$refs.regCursorM.style.display = "none"
      this.$refs.regCursorY.style.display = "none"
    }
    this.stickyHeader();
    this.follower();
    if(this.$route.name === "work"){
      this.$refs.content.intro();
    }
    window.addEventListener("load", () => {
      this.aCursor();
      this.smoothScroll();
      document.addEventListener("mousemove", (e) => this.magnetize("#socialMedia img", e))
    })
  },
  updated(){
      this.aCursor();
  },
  
  watch:{
    $route (to, from){
      // this.aCursor();
      let toRouteId = to.matched[0].props.default.id
      let fromRouteId = from.matched[0].props.default.id
      if(toRouteId < fromRouteId){
        return this.slide = "slideRight"
      }else if(this.$route.name === "info"){
        return this.slide = "slideUp"
      }else{
        return this.slide = "slideLeft"
      }
    }
  },
  
  computed: {
    back: function (){
      if (this.$route.name ==="work" || this.$route.name ==="welcome"){
        return false
      }
      if(this.projList === true){
        return false
      }else{
        return true
      }
    }
  },
  
  methods: {
    stickyHeader(){
      window.addEventListener("scroll", function(){
        if (window.scrollY > 30){
          document.getElementById('header').classList.add("sticky")
          // this.isScrolled = true
        }else{
          document.getElementById('header').classList.remove("sticky")
          // this.isScrolled = false
        }
      })
    },

    socialPos(){
      window.addEventListener("scroll", function(){
        if (window.scrollY > 50){
          document.getElementById('header').classList.add("sticky")
          // this.isScrolled = true
        }else{
          document.getElementById('header').classList.remove("sticky")
          // this.isScrolled = false
        }
      })
    },
    goBack(){
    this.hideList();
    this.$router.go(-1);
          gsap.to(this.$refs.circle, {duration: 0.3, scale:1})
    },
    showList(){
      this.projList = !this.projList
      if(this.projList){
        this.$refs.header.style.color = "white"
      }else{
        this.$refs.header.style.color = "#2b2b2b"
      }
    },
    hideList(){
      if(this.projList){
        this.projList = !this.projList
        this.$refs.header.style.color = "#2b2b2b"
      }
    },
    hoverCursor(){
      gsap.to(this.$refs.circle, {duration: 0.3, scale:4})
      gsap.to(this.$refs.regCursor, {duration:0.3, opacity: 0})
      gsap.to(this.$refs.regCursorC, {duration: 0.3, opacity: 0})
      gsap.to(this.$refs.regCursorM, {duration: 0.3, opacity: 0})
      gsap.to(this.$refs.regCursorY, {duration: 0.3, opacity: 0})
    },
    normalCursor(){
      gsap.to(this.$refs.circle, {duration: 0.3, scale:1})
      gsap.to(this.$refs.regCursor, {duration: 0.3, opacity: 1})
      gsap.to(this.$refs.regCursorC, {duration: 0.3, opacity: 1})
      gsap.to(this.$refs.regCursorM, {duration: 0.3, opacity: 1})
      gsap.to(this.$refs.regCursorY, {duration: 0.3, opacity: 1})
    },
    aCursor(){
      const self = this
      const links = Object.values(document.querySelectorAll("a"))
      links.forEach( link => {
        link.addEventListener("mouseenter", function(){
          gsap.to(self.$refs.circle, {duration: 0.3, scale:4})
          gsap.to(self.$refs.regCursor, {duration:0.3, opacity: 0})
          gsap.to(self.$refs.regCursorC, {duration: 0.3, opacity: 0})
          gsap.to(self.$refs.regCursorM, {duration: 0.3, opacity: 0})
          gsap.to(self.$refs.regCursorY, {duration: 0.3, opacity: 0})
        });
        link.addEventListener("mouseleave", function(){
          gsap.to(self.$refs.circle, {duration: 0.3, scale:1})
          gsap.to(self.$refs.regCursor, {duration: 0.3, opacity: 1})
          gsap.to(self.$refs.regCursorC, {duration: 0.3, opacity: 1})
          gsap.to(self.$refs.regCursorM, {duration: 0.3, opacity: 1})
          gsap.to(self.$refs.regCursorY, {duration: 0.3, opacity: 1})
        })
      })
    },
    moveCursor(e) {
      gsap.to(this.$refs.circle, {duration:0.5, x : e.clientX , y : e.clientY});
      gsap.to(this.$refs.regCursor, {duration:0.05, x : e.clientX , y : e.clientY});
      gsap.to(this.$refs.regCursorC, {duration:0.15, x : e.clientX , y : e.clientY});
      gsap.to(this.$refs.regCursorM, {duration:0.25, x : e.clientX , y : e.clientY});
      gsap.to(this.$refs.regCursorY, {duration:0.35, x : e.clientX , y : e.clientY});
      },
    hideCursor() {
      gsap.to(this.$refs.circle, {duration: 0.3, opacity: 0});
      gsap.to(this.$refs.regCursor, {duration: 0.3, opacity: 0});
      gsap.to(this.$refs.regCursorC, {duration: 0.3, opacity: 0});
      gsap.to(this.$refs.regCursorM, {duration: 0.3, opacity: 0});
      gsap.to(this.$refs.regCursorY, {duration: 0.3, opacity: 0});
    },
    showCursor() {
      gsap.to(this.$refs.circle, {duration: 0.3, opacity: 1});
      gsap.to(this.$refs.regCursor, {duration: 0.3, opacity: 1});
      gsap.to(this.$refs.regCursorC, {duration: 0.3, opacity: 1});
      gsap.to(this.$refs.regCursorM, {duration: 0.3, opacity: 1});
      gsap.to(this.$refs.regCursorY, {duration: 0.3, opacity: 1});
    },
    follower(){
      document.addEventListener('mouseenter', this.showCursor);
      window.addEventListener('mousemove', this.moveCursor);
      document.addEventListener('mouseleave', this.hideCursor);
    },
    smoothScroll(){
      let scroller = document.querySelector('.content')
      let trigger = document.querySelector('#app')
      let height = scroller.scrollHeight;
      document.body.style.height = height + "px";
      document.documentElement.scrollTop = 0;
      if(!this.isMobile){
        gsap.to(scroller, {
          y: () => -(scroller.scrollHeight - document.documentElement.clientHeight) + "px", ease: "none",
          scrollTrigger: {
            trigger: trigger,
            start: "top top",
            invalidateOnRefresh: true,
            // markers: true,
            scrub: 0.5,
            end: "bottom bottom",
          }
        })
      }else{
        gsap.to(scroller, { 
          y: () => -(scroller.scrollHeight - document.documentElement.clientHeight) + "px", ease: "none",
          scrollTrigger: {
            trigger: trigger,
            start: "top top",
            invalidateOnRefresh: true,
            scrub: 0.1,
            end: "bottom bottom",
          }
        })
      }
    },
    
    magnetize(el, e){
      var cursorX = e.pageX, cursorY = e.pageY;                                       //mouse position
      const items = Object.values(document.querySelectorAll(el));
      [].forEach.call(items, function(item) {
        const elementX = item.getBoundingClientRect().left+(item.clientWidth/2);       //position of the element
        const elementY = item.getBoundingClientRect().top+(item.clientHeight/2);
        var deltaX = Math.floor((elementX - cursorX)) * -0.5;        //magnatisation strength distance the element moves = distance*constant
        var deltaY = Math.floor((elementY - cursorY)) * -0.5;

        const customDist = 20;                                     //distance when nagnatisation is triggered
        var distance = Math.floor(Math.sqrt(Math.pow(cursorX - elementX, 2) + Math.pow(cursorY - elementY, 2)));
        if(distance < customDist){
          gsap.to(item, {duration: 0.3, y: deltaY, x: deltaX, scale:1.2});
        }
        else {
          gsap.to(item, {duration: 0.45, y: 0, x: 0, scale:1});
        }     
      });
    },


  }
}

</script>



<style lang="scss">

@import './css/app.scss';
html {
    font-family: $SerifFont;
    background-color: #FFF;
    // overflow: hidden;
    cursor: none;
    width: 100%;
    ::-webkit-scrollbar {
      height: 15px;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: darkred;
    }
    ::-webkit-scrollbar-track {
      border: solid 10px; 
      border-radius: 10px;
    }
    @include for-phone-only(){
      // overflow: scroll;
			::-webkit-scrollbar {
        display: none;
      }
		}
}
body {
  width: 100%;
  margin: 0;
  background-color: #F2F2F2;

}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 1px;
  margin-top: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  position:relative; 
  .viewport {
    background-color: $white;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .content{
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    // margin-top: $headerHeight;
    padding: 0;
    @include for-phone-only(){
      padding: 0;
    }
  }
}

#header{
    height: $headerHeight;
    font-family: $SansFont;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    transition: all 0.4s ease;
    z-index: 20;
  &.sticky {
    height: $headerHeight - 20px;
  }
}
#logo{
    width: 50%;
    padding-left: 0;
    align-items: center;
    display: flex;
    // height: 60px;
    a{
      font-family: 'Antic Didone';
      display: block;
      letter-spacing: 1px;
      font-size: 1em;
      font-weight: 700;
      text-align: left;
      display: inline-block;
      color: inherit;
      // height: 60px;
        width: 120px;
      img{
        display: block;

      }
      &:hover{
        color: $red;
        text-decoration: none;
      }
    }
}
#backBtn{
    margin: 0 0 0 5%;
    height: auto;
  svg{
    stroke: currentColor;
    fill:none;
    stroke-miterlimit:10;
    stroke-width: 1.5;
    width: 25%;
  }
}
#nav{
    width: 50%;
    font-size: 0.8em;
    font-weight: 400;
    ul{
        margin: 0;
        padding: 0;
        text-align: right;
        list-style-type: none;
        li{
            width: auto;
            margin: 0;
            display: inline-block;
            padding: 0 3% 0 3%;
            a {
              text-align: center;
              color: inherit;
            }
        }
    }
}

#socialMedia{
  @include for-phone-only(){
    padding: 0;
  width: 10vw;
    flex-direction: column;
  }
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30vw;
  padding: 0 1rem 2rem 0;
  img{
    @include for-phone-only(){
    margin: 0;
    }
    width: 30px;
    margin: 0 1rem 0 0;
  }
}

.circle {
    pointer-events: none;
    transform-origin: center;
    mix-blend-mode: exclusion;
    height: 10px;
    width: 10px;
    margin: -6px 0 0 -6px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: none;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
}
.regCursor {
  stroke: black;
  stroke-width: 2;
  pointer-events: none;
  border: none;
  display: block;
  position: fixed;
  margin: -19px 0 0 -19px;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  svg{
    display: block;
    shape-rendering: geometricPrecision;
  }
}
.regCursorC {
  stroke-width: 1;
  pointer-events: none;
  border: none;
  display: block;
  position: fixed;
  margin: -19px 0 0 -19px;
  top: 0;
  left: 0;
  z-index: 900;
  overflow: hidden;
  svg{
    display: block;
    shape-rendering: geometricPrecision;
  }
}
.regCursorM {
  stroke-width: 1;
  pointer-events: none;
  border: none;
  display: block;
  position: fixed;
  margin: -19px 0 0 -19px;
  top: 0;
  left: 0;
  z-index: 800;
  overflow: hidden;
  svg{
    display: block;
    shape-rendering: geometricPrecision;
  }
}
.regCursorY {
  stroke-width: 1;
  pointer-events: none;
  border: none;
  display: block;
  position: fixed;
  margin: -19px 0 0 -19px;
  top: 0;
  left: 0;
  z-index: 700;
  overflow: hidden;
  svg{
    display: block;
    shape-rendering: geometricPrecision;
  }
}

//Router transitions

.slideLeft-enter-active, .slideLeft-leave-active{
  transition: all 0.5s ease;
}
.slideLeft-enter-active{
    transition-delay: 0s;
}
.slideLeft-enter {
  margin-left:100%;
  opacity: 0;
}
.slideLeft-enter-to, .slideLeft-leave{
  transform: translateX(0);
  opacity: 100;
}
.slideLeft-leave-to{
  margin-left:-100%;
  opacity: 0;
}

.slideRight-enter-active, .slideRight-leave-active{
  transition: all 0.5s ease;
}
.slideRight-enter-active{
    transition-delay: 0s;
}
.slideRight-enter {
  margin-left:-100%;
  opacity: 0;
}
.slideRight-enter-to, .slideRight-leave{
  transform: translateX(0);
  opacity: 100;
}
.slideRight-leave-to{
  margin-left:100%;
  opacity: 0;
}

.slideUp-enter-active, .slideUp-leave-active{
  transition: all 0.5s ease;
}
.slideUp-enter-active{
    transition-delay: 0s;
}
.slideUp-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slideUp-enter-to, .slideUp-leave{
  transform: translateX(0);
  opacity: 100;
}
.slideUp-leave-to{
  transform: translateY(-100%);
  opacity: 0;
}

.clipReveal-enter-active, .clipReveal-leave-active{
  transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.clipReveal-enter {
  clip-path: circle(0% at 100% 0%);
}
.clipReveal-enter-to, .clipReveal-leave{
  clip-path: circle(100%);
}
.clipReveal-leave-to{
  clip-path: circle(0% at 100% 0%);
}
</style>
