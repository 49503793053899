<template>
  <div class="content work">
    <section id="introWrapper" ref="introWrapper">
      <div id="showreel" ref="showreel">
        <video v-if="!isMobile" src="../assets/video/showreel_2020.mp4" autoplay muted loop playsinline controls></video>
        <video v-if="isMobile" src="../assets/video/showreel_2020_small.mp4" autoplay muted loop playsinline controls></video>
      </div>
      <div id="intro" ref="intro">
        <div class="introText">
          <div> <h2 ref="name"> Hello!</h2> </div>
          <div>
            <p ref="introP">
              My name is Yamen Albadin, I am a multidisciplinary designer based in Bristol, UK <br>
              I have over 7 years of experience helping agencies tell stories through design. <br>
              I love everything design related, and I have worked in video editing, graphic design and 3D design, <br>
              but I mainly specialise in motion graphics, helping brands engage audiences around the world<br>
              Below are some examples of my work. Have a look and get in touch<br>
            </p>
          </div>
        </div>
        <div class="arrow" ref="arrow">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
            x="0px" y="0px" width="60px" height="90px" viewBox="0 0 39.2 130.4" style="enable-background:new 0 0 39.2 130.4;" xml:space="preserve">
            <defs></defs>
            <polygon id="scrollArrow" ref="scrollArrow" points="35,65.1 29.8,59.9 23.2,66.6 23.2,5.9 16,5.9 16,66.6 9.4,59.9 4.2,65.1 14.5,75.4 19.6,80.5 "/>
          </svg>
        </div>
      </div>
    </section>




    <section class="project" v-for="(project, index) in $root.projects" v-bind:key="project.name">
      <div class="projNumber"><h3>0{{index + 1 }}/</h3></div>
      <div class= "projRow">
        <div class="projectSubtitle" v-if="!isMobile">
          <div>
            <h6>{{project.category}}</h6>
          </div>
        </div>
        <router-link :to="project"><img :src ="project.img"></router-link>
      </div>
      <div class="title">
        <router-link :to="project"> <h1 class = "projectTitle">{{project.title}}</h1></router-link>
        <div class="sub">
            <hr/>
        </div>
      </div>
    </section>


  </div><!--end content-->
</template>



<script>
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default{
  name: 'work',
  data(){
    return{
      reveals: []
    }
  },
  props: [
    'hideCursor'
  ],
  mounted(){
    this.arrowCursor();
    this.imgHover();
    this.introSize();


    if (window.DeviceOrientationEvent) {
      window.addEventListener("deviceorientation", this.tiltMove, !0);
    }

    // setTimeout(() => {
    //   this.revealOnScroll();
    //   this.skewOnScroll();
    // }, 1000);

    ScrollTrigger.refresh();
  },

  
  methods: {
    arrowCursor(){
    const cursor = 'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="32" width="32" fill="none" stroke="pink" stroke-width="5" stroke-miterlimit="10" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve"><polygon class="st0" points="73.3,11.9 55.9,29.3 78.4,51.7 2.6,51.7 2.6,76.3 78.4,76.3 55.9,98.7 73.3,116.1 108,81.4 125.4,64"/></svg>\') 100 52, wait'
    var imgNodeList = document.querySelectorAll(".projRow img"); /* return a nodeList */
    var projectImg = Array.apply(null, imgNodeList); /* convert nodeList to an array */
    for(let i=0; i<projectImg.length; i++){
        projectImg[i].style.cursor = cursor;
      }
    },
    
    imgHover(){
      var projectImg = Object.values(document.querySelectorAll(".projRow img"));
      var self = this
      for(var i=0; i<projectImg.length; i++){

        projectImg[i].addEventListener("mouseenter", function(){
          self.hideCursor();
        })

        projectImg[i].addEventListener("mousemove", function(e){
          let cursorX = e.clientX;
          let cursorY = e.clientY;
          let halfWindowWidth = (window.innerWidth) / 2;
          let halfWindowHeight = (window.innerHeight) / 2;
          let moveX = (cursorX - (halfWindowWidth)) * -0.1;
          let moveY = (cursorY - (halfWindowHeight)) * -0.1;
          gsap.to(this, {duration: 1, x: moveX * 0.2, y: moveY * 0.1});
          let projectTitle = this.closest("section").querySelector(".projectTitle")
          let projectSubtitle = this.closest("section").querySelector(".projectSubtitle div h6")
          let line = this.closest("section").querySelector("hr")
          gsap.set(line, {width:"100%"})
          gsap.to(line, {duration: 1, width:"130%"})
          gsap.to(projectTitle, {duration: 1, color:"#bd2015"})
          gsap.to(projectSubtitle, {duration: 0.5, marginBottom:0})
        })
        projectImg[i].addEventListener("mouseout", function(){
          self.$parent.showCursor();
          gsap.to(this, {duration: 1, x: 0, y: 0});
          let projectTitle = this.closest("section").querySelector(".projectTitle")
          let projectSubtitle = this.closest("section").querySelector(".projectSubtitle div h6")
          let line = this.closest("section").querySelector("hr")
          gsap.to(line, {duration: 1, width:"100%"})
          gsap.to(projectTitle, {duration: 1, color:"#2b2b2b"})
          gsap.to(projectSubtitle, {duration: 0.5, marginBottom:"-3em"})
        })
      }
    },
    
    tiltMove(event) {
      var projectImg = Object.values(document.querySelectorAll(".projRow img"));
      let beta = (event.beta).toFixed(2);
      let gamma = (event.gamma).toFixed(2);
      beta -= 25;
      beta *= -0.5;
      gamma *= -0.7;
      gsap.to(projectImg, {
        duration: 1.2,
          x: gamma * 0.7,
          y: beta * 0.7
      });
    },
    
    intro(){
      var tl = gsap.timeline({Delay: 1});
      tl.set(this.$refs.name,{y: '100%'})
      tl.set(this.$refs.introP, {y:"130%"})
      tl.set(this.$refs.scrollArrow, {y:-100})

      tl.to(this.$refs.name, {duration:.5, y: '0%', delay:1})
      tl.to(this.$refs.introP, {duration: .5, y:'0%'}, "-=.25")
      tl.to(this.$refs.scrollArrow, {duration:1.5, y:150, delay:3, repeatDelay:1, repeat:5},0)
    },

    introSize(){
      let introWrapper = this.$refs.introWrapper;
      let showreel = this.$refs.showreel;
      let intro = this.$refs.intro;
      showreel.style.height = introWrapper.clientHeight-intro.clientHeight - 40 + "px";
      window.addEventListener("resize", function(){
        showreel.style.height = introWrapper.clientHeight-intro.clientHeight - 40 + "px";
      })
    },
     revealOnScroll() {
        this.reveals = gsap.utils.toArray(".project").map((project, i) => {
          return gsap.from(project, {
            scrollTrigger: {
              id: "revealer" + i,
              trigger: project,
              start: "top 80%",
              end: "top 35%",
              scrub: 1,
            },
            opacity: 0,
          });
        });
      },
    skewOnScroll(){
      let proxy = { skew: 0 },  skewSetter = gsap.quickSetter(".project", "skewY", "deg"),    clamp = gsap.utils.clamp(-4, 4);
      
      ScrollTrigger.create({
        id: "skewer",
        onUpdate: (self) => {
          let skew = clamp(self.getVelocity() / -1000)
          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            if (Math.abs(skew) > 2 || Math.abs(skew)< -2){
              proxy.skew = skew;
              gsap.to(proxy, {skew: 0, duration: 0.5, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
            }
          }
        },
        scrub: 1
      })
    }
  }
}
</script>


<style lang="scss" scoped>
$white: #F2F2F2;
$black: #2b2b2b;
$red:#bd2015;

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
#introWrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: stretch;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: auto;
  text-align: center;
  #showreel{
    video{
      width: 100%;
      max-width: 1250px;
      height: 95%;
      margin: auto;
      filter: grayscale(30%);
      filter: brightness(0.9);
    }
  }
  #intro{
    // height: 25%;
    display: flex;
    // justify-content: space-between;
  }
  .introText{
    @include for-phone-only(){
      width: 80vw;
    }
    width: 70vw;
    padding: 0 0 3vh 5vw;
    text-align: left;
    p{
      @include for-phone-only(){
        font-size: 0.7rem;
      }
    }
    div{
      overflow: hidden;
    }
    h2{
      margin: 0;
    }
  }
  .arrow{
    padding: 0 auto 5vh 0;
    align-self: flex-end;
    svg{
      stroke: $white;
      stroke-width: 1;
      fill: none;
    }
  }
}
section{
  @include for-phone-only(){
    height: auto;
    padding: 20px 0;
  }
  padding: 40px 0;

  .projNumber{
    width: 100%;
    text-align: left;
    h3{
      @include for-phone-only(){
      margin: 0 0 0 2.5%;
      }
      margin: 0 0 0 calc(11vw + 1rem);
    }
  }
  .projRow {
    @include for-phone-only(){
      width: 95vw;
      padding: 0;
      justify-content: center;
      margin: auto;
    }
    display: flex;
    justify-content: flex-end;
    width: calc(72vw - 2rem);
    padding: 0 calc(11vw + 1rem) 0 calc(11vw + 1rem);
    text-align: right;
    // margin: auto;
    img{
      @include for-phone-only(){
        width: 100%;
      }
      width: 58vw;
      display: block;
    }
  }
  .projectSubtitle{
    
    align-self: flex-end;
    padding: 0 1rem 0 0;
    div{
      transform: rotate(-90deg);
      transform-origin: bottom left;
      overflow: hidden;
      margin: 0 0 0 100%;
      width: 200%;
      h6{
        font-family: 'Montserrat', helvetica, sans-serif;
        font-weight: 400;
        text-align: left;
        letter-spacing: 0.05em;
        margin: 0 0 -3rem 0;
      }
    }
  }
  
  .title{
    @include for-phone-only(){
      order: 1; text-align: center;
      width: 95%;
      padding: 0;
      margin: auto;
    }
    width: calc(72vw - 2rem);
    padding: 0 calc(11vw + 1rem) 0 calc(11vw + 1rem);
    text-align: right;
    overflow: hidden;
    
    h1{
      margin: 0;
      padding: 1rem 0% 1rem 0;
    }  
    h6{
      margin: 0;
      width: 97%;
    }
    .sub{
      flex-direction: column;
      align-items: center;
      text-align: left;
      hr{
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, #404040, #404040);
        border: none;
        margin: 0;
      }
    }
  }
}


</style>