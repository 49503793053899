<template>
    <div class="content info">
        <div id="introSection">
            <div class="redHover" @mouseover="revealMe" @mouseout="concealMe"></div>
            <div id="me">
                <img ref="img" src="../assets/imgs/self-portrait.jpg"/>
                <p ref="intro">My name is Yamen Albadin, I'm a visual designer with a passion for simplicity and minimalism, I work as a motion graphic designer in the UK and hold a degree in visual communication</p>
            </div>
        </div>
        <div id="skillsSection">
            <div class="whiteHover" @mouseover="reveal" @mouseout="conceal"></div>
            <div class="skills">
                <div class="skillsText">
                    <p ref="skills">I'm interested in 3D design, motion graphics, graphic design and coding, my skillset covers a wide range of software. I use the majority of the Adobe software and Cinema 4D, I also enjoy coding with JavaScript, this website is an example made with Vue.js, Sass and GSAP</p>
                </div>
                <div id="software" ref="software">
                    <div><img src="../assets/imgs/logos/Adobe.svg" alt="adobe"></div>
                    <div><img src="../assets/imgs/logos/Maxon.svg" alt="Cinema 4D"></div>
                    <div><img src="../assets/imgs/logos/Unofficial_JavaScript.svg" alt="JavaScript"></div>
                    <div><img src="../assets/imgs/logos/Vuejs.svg" alt="Vue.js"></div>
                    <div><img src="../assets/imgs/logos/Jquery.svg" alt="jQuery"></div>
                    <div><img src="../assets/imgs/logos/Green_sock.svg" alt="GSAP"></div>
                </div>
            </div>
        </div>
        <div id="contactSection">
            <div class="redHover" ></div>
            <div class="contactDetails">
                <a></a>
                <p>I'm open to new collaborations and interesting ideas and projects. If you have any question please get in touch! <a href="mailto:yamengfx@gmail.com">yamengfx@gmail.com</a></p>
            </div>
        </div>
    </div>
</template>

<script>

import {gsap} from 'gsap';

export default {


    mounted(){
    },
    methods:  {
        revealMe(){
            const img = this.$refs.img
            const intro = this.$refs.intro
            if(!this.isMobile && window.innerWidth >= 599){
                gsap.to(img, {duration: 1, clipPath:"inset(0% 0.01% 0% 0%)", marginLeft: "3%", ease: "power2"})
                gsap.to(intro, {duration: 1, marginLeft:"2%", ease: "power2"})
            }else{
                intro.style.marginLeft = "5%"
                img.style.clipPath = "inset(0% 0.01% 0% 0%)"
            }
        },
        concealMe(){
            const img = this.$refs.img
            const intro = this.$refs.intro
            if(!this.isMobile && window.innerWidth >= 599){
            gsap.to(img, {duration: 1, clipPath:"inset(0% 100% 0% 0%)", marginLeft: "0", ease: "power2"})
            gsap.to(intro, {duration: 1, marginLeft:"-5%", ease: "power2"})
            }else{
                intro.style.marginLeft = "5%"
                img.style.clipPath = "inset(0% 0.01% 0% 0%)"
            }
        },
        reveal(){
            const software = this.$refs.software
            const skills = this.$refs.skills
            if(!this.isMobile && window.innerWidth >= 599){
                gsap.to(software, {duration: 1, opacity: "1", ease: "Power2.out"})
                gsap.to(skills, {duration: 1, paddingLeft: "15%", ease: "Power2.out"})
            }else{
                software.style.opacity = 1;
                skills.style.paddingLeft = "0%";
            }
        },
        conceal(){
            const software = this.$refs.software
            const skills = this.$refs.skills
            if(!this.isMobile && window.innerWidth >= 599){
                gsap.to(software, {duration: 1, opacity: "0", ease: "Power2.inOut"})
                gsap.to(skills, {duration: 1, paddingLeft: "33.3%", ease: "Power2.out"})
            }else{
                software.style.opacity = 1;
                skills.style.paddingLeft = "0%";
            }
        },

    }
}
</script>

<style lang="scss" scoped>
@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
.info{
    font-family: 'Lato', helvetica, sans-serif;
    padding: 0 0 0 0;
    margin-top: 0;
}
#introSection{
    height: 70vh;
    padding-top: 30vh;
}
#me{
    padding: 0 3% 3rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @include for-phone-only(){
        flex-direction: column;
        padding: 0;
    }

    img{
        width: 25%;
        clip-path: inset(0% 100% 0% 0%);
        // margin: 0 0 auto 0;
        height: auto;
        @include for-phone-only(){
            max-width: 50%;
            clip-path: none;
            // clip-path: circle(100% at 45% 0%);
        }
    }
    p{
        margin-left: -5%;
        width: 50%;
        font-size: 1rem;
        @include for-phone-only {
            font-size: 1rem;
            width: 70%;
            margin-left: 5%;
            }
    }
}
.redHover{
    height: 20px;
    width: 50%;
    border-radius: 0%;
    background: #bd2015;
    align-self: flex-start;
    // margin-right: -30px;
    // position:relative;
}
.pulse{
    height: 20px;
    width: 20px;
    border-radius: 0%;
    background: #F2F2F2;
    // margin-right: -30px;
    float: left;
}
#skillsSection{
    background:#bd2015;
    color: white;
    height: 70vh;
    padding: 30vh 0 0 0;
    .skills{
        display: flex;
        padding: 1em 0 1em 0;
        @include for-phone-only(){
            flex-direction: column;
        }
    }
    .skillsText{
        width: 60%;
        @include for-phone-only(){
            width: 95%;
            padding: 0 0 0 5%;
        }
    }
    p{
        width: 66.7%;
        padding-left: 33.3%;
        padding-right: 0;
        @include for-phone-only {
            width: 70%;
            padding: 0;
            margin-right: 0;
        }
    }
    #software{
        width: 30%;
        padding: 0 10% 0 0;
        display: flex;
        justify-content: space-around flex-start;
        align-items:center;
        flex-wrap: wrap;
        opacity: 0;
        @include for-phone-only(){
            opacity: 1;
            width: 100%;
            margin: 5vh 0 0 0;
            padding: 0 0 0 5%;
        }
        div{
            width: 5em;
            height: 50%;
            margin: 0 0 5% 0;
            display: flex;
            align-items: center;
        }
        img{
            display: block;
            width: 50px;
            margin: 0 auto 0 auto;
        }
    }
}
.whiteHover{
    height: 20px;
    width: 50%;
    margin: 0 0 0 50%;
    background: white;
    // margin-right: -30px;
    // position:relative;
}
#contactSection{
    padding: 40vh 0 0 0;
    height: 60vh;
}
.contactDetails{
    padding: 0 3% 3em 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    a{
        width: 25%;
        margin: 0 0 auto 0;
        height: auto;
        color: red;
        padding-top: 1rem;
    }
    p{
        margin-left: -5%;
        width: 50%;
    }
}

</style>