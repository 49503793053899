import Vue from 'vue';
import VueRouter from 'vue-router';
import work from '../views/work';
import info from '../views/info.vue';
import FAHalitYozgat from '../views/FAHalitYozgat';
import FAHarithAugustus from '../views/FAHarithAugustus';
import EIKON from '../views/EIKON';
import penhaligons from '../views/penhaligons';
import visualThoughts from '../views/visualThoughts';
import chess from '../views/chess';
import farfetch from '../views/farfetch';
import bauhaus from '../views/bauhaus';


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'work',
    component: work,
    props: {id: 0}
  },
  {
    path: '/info',
    name: 'info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: info, /*() => import(/* webpackChunkName: "about" '../views/info.vue')*/
    props: {id: 20}
  },
  {
    path: '/FAHarithAugustus',
    name: 'FAHarithAugustus',
    component: FAHarithAugustus,
    props: {id: 1}
  },
  {
    path: '/FAHalitYozgat',
    name: 'FAHalitYozgat',
    component: FAHalitYozgat,
    props: {id: 2}
  },
  {
    path: '/visualThoughts',
    name: 'visualThoughts',
    component: visualThoughts,
    props: {id: 3}
  },
  {
    path: '/chess',
    name: 'chess',
    component: chess,
    props: {id: 4}
  },
  {
    path: '/penhaligons',
    name: 'penhaligons',
    component: penhaligons,
    props: {id: 5}
  },
  {
    path: '/EIKON',
    name: 'EIKON',
    component: EIKON,
    props: {id: 6}
  },
  {
    path: '/farfetch',
    name: 'farfetch',
    component: farfetch,
    props: {id: 7}
  },
  {
    path: '/bauhaus',
    name: 'bauhaus',
    component: bauhaus,
    props: {id: 8}
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /*eslint-disable*/
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
      if ('scrollRestoration' in history) { 
        history.scrollRestoration = 'manual'; 
      }
    })
  }
})

export default router
