<template>
    <div class="content farfetch">
        <div class="cover">
            <img src="../assets/imgs/Farfetch/Game_on_Japan.png"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>Gamen On Japan</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>

        </div>
        <div class="section">
            <video src="../assets/video/Farfetch/FF_reel_1.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
                <video src="../assets/video/Farfetch/Farfetch_Japan_trailer.mp4" controls></video>
        </div>
        <projectsNav/>
            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'farfetch',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("farfetch")
        }
    },
    props: [
    ]

}
</script>

<style scoped>

</style>