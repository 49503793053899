<template>
    <div class="content Bauhaus">
        <div class="cover">
            <img src="../assets/imgs/Bauhaus/Bauhaus5825.jpg"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>BAUHAUS / VKHUTEMAS</h2>
                <h6>How Two Art Schools in Germany and Russia Defined Modern Design</h6>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5722.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5701.jpg" />
            </div>
        </div>
        <div class="section">
            <img src="../assets/imgs/Bauhaus/MG_5713.jpg" />
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5734.jpg" style="padding-bottom: 0.75rem" />
                <img src="../assets/imgs/Bauhaus/MG_5818.jpg" style="padding-top: 0.75rem"/>
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5740_1.jpg" />
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5838.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5767.jpg" />
            </div>
        </div>
        <div class="section">
            <img src="../assets/imgs/Bauhaus/MG_5779.jpg" />
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5784.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5792.jpg" />
            </div>
        </div>
        <div class="section">
            <img src="../assets/imgs/Bauhaus/MG_5849.jpg" />
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5795.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5859.jpg" />
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5803.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Bauhaus/MG_5809.jpg" />
            </div>
        </div>
                
        <projectsNav/>
            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'Bauhaus',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("bauhaus")
        }
    },
    props: [
    ]
}
</script>

<style lang="scss" scoped>

.section{
    padding: 0;
    img{
        width: calc(100% - 1.5rem);
        padding: 0.75rem;
    }
}

.column{
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>