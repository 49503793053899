<template>
    <div class="content EIKON">
        <div class="cover">
            <img src="../assets/imgs/EIKON/REUTERS_EIKON.png"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>Thompson Reuters EIKON</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>

        </div>
        <div class="section">
            <video src="../assets/video/EIKON/section_1.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <video src="../assets/video/EIKON/section_2.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <video src="../assets/video/EIKON/section_3.mp4" autoplay loop muted playsinline></video>
        </div>
        <projectsNav/>
            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'EIKON',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("EIKON")
        }
    },
    props: [
    ]
    
}
</script>

<style scoped>

</style>