<template>
  <div class="content chess">
        <div class="cover">
            <img src="..//assets/imgs/chessMaps/cover.png" alt="">
        </div>
        <div class="section description">
            <div class="column title">
                <h2>Chess maps</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>

        </div>
        <div class="section">
            <div class="column">
                <p class="paragraph">In 1996, a historic chess match took place in Philadelphia between Chess World Champion Garry Kasparov and IBM's chess computer Deep Blue. The match consisted of six games and ended with a score of 4-2 to Kasparov. Game 1 was the first game a chess computer won against a reigning world champion under tournament conditions. This match was hugely significant, as it showed that with more development, AI could match humans' intellectual abilities. Indeed, IBM kept working on Deep Blue, and a rematch took place the following year. <br><br> Here I present the designs inspired by and based on the games of this match. In these designs, the lines represent the moves, and the circles represent captures. I wasn't aiming to create a diagram but rather a representation/data visualisation that shows pressure points and activity areas on the board. I chose the colours carefully based on the movement and values of the pieces and restricted the choice in order not to confuse the viewer. The circles show where captures and exchanges took place. The larger concentric circles illustrate the release of the high tension in the position on one square. Below you can see the designs from the rematch in 1997.</p>
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-1.png" alt="">
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-2.png" alt="">
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-3.png" alt="">
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-4.png" alt="">
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-5.png" alt="">
            </div>
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_1-6.png" alt="">
            </div>
            <div class="column">
            </div>
        </div>
        <div class="section">
            <div class="column">
                <p class="paragraph">The rematch in 1997 ended with 3½–2½ for Deep Blue and became a symbol that AI was catching up with human intelligence. The match was controversial for some time. In game 2, Kasparov protested and accused IBM of cheating, alleging that a certain grandmaster was behind move 36. axb5 axb5 37.Be4. Kasparov tried unconventional openings to try to get Deep Blue out of the opening books. Some criticised Kasparov's play, downplaying Deep Blue's win. Years later, however, Kasparov said in a podcast: "While writing the book I did a lot of research – analysing the games with modern computers, also soul-searching – and I changed my conclusions. I am not writing any love letters to IBM, but my respect for the Deep Blue team went up, and my opinion of my own play, and Deep Blue's play, went down. Today you can buy a chess engine for your laptop that will beat Deep Blue quite easily."</p>
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-1.png" alt="">
            </div>  
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-2.png" alt="">
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-3.png" alt="">
            </div>  
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-4.png" alt="">
            </div>
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-5.png" alt="">
            </div>  
        </div>
        <div class="section">
            <div class="column">
                <img src="..//assets/imgs/chessMaps/Kasparov_vs_DeepBlue_2-6.png" alt="">
            </div>
            <div class="column">
            </div>  
        </div>
        <projectsNav/>
            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'chess',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("chess")
        }
    },
    props: [
    ]
    
}
</script>

<style lang="scss" scoped>

.section{

    .column{
        padding: 1rem 3rem;
        .paragraph{
            padding: 0 0 0 5vw;
        }
    }
    img{
            display: block;
            margin: auto;
            max-width: 100%;
            max-height: 90vh;
            width: auto;
            height: auto;
        }
}

</style>