<template>
    <div class="content FA">
        <div class="cover">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_6.jpg"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>The Killing Of Harith Augustus</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description">
                </p>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_days_3.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_Days_1.jpg"/>
            </div>
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_Days_2.jpg"/>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_days_1.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_hours_1.jpg"/>
        </div>
        <div class="section">
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_1.jpg"/>
            </div>
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_2.jpg"/>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_days_2.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_minutes_1.jpg"/>
            </div>
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_minutes_2.jpg"/>
            </div>
        </div>
        <div class="section">
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_3.jpg"/>
            </div>
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_4.jpg"/>
            </div>
        </div>
        <div class="section">
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_5.jpg"/>
            </div>
            <div class="column">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_6.jpg"/>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_minutes.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_seconds.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <img src="../assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_seconds_1.jpg"/>
        </div>
        <div class="section">
            <video src="../assets/video/FA/HarithAugustus/FA Harith Augustus 1080_milliseconds.mp4" autoplay loop muted playsinline></video>
        </div>
        <projectsNav/>            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'FA',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("FAHarithAugustus")
        }
    },
    props: [
    ],

}
</script>

<style lang="scss" scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) { @content; }
}
.section{
    img{
        width: calc(80% - 1.5rem);
        margin: auto;
        @include for-phone-only(){width: 100%;}
    }
    video{
        width: 60%;
        margin: auto;
        @include for-tablet-landscape-only(){width: 80%;}
        @include for-phone-only(){width: 100%;}
    }
}

.column{
    padding: 0 0.75rem 0 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

</style>