<template>
  <div class="navSection">
    <div class="column prev" >
      <router-link :to="previous">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
        x="0px" y="0px" width="37.5px" height="32.3px" viewBox="0 0 37.5 32.3" style="enable-background:new 0 0 37.5 32.3;"
        xml:space="preserve">
        <defs></defs>
        <polygon class="arrowPrev" points="0.7,16.2 5.9,21.3 16.2,31.6 21.3,26.4 14.6,19.8 37,19.8 37,12.5 14.6,12.5 21.3,5.9 16.2,0.7 "/>
        </svg>
        <span>PREVIOUS PROJECT</span>
      </router-link>
    </div>
    <div class="column next" >
      <div class="nextWrapper">
        <div class="nextProject">
          <router-link :to="next" @mouseenter.native= "mouseoverNext" @mouseleave.native= "mouseoutNext">
            <span>NEXT PROJECT</span>
          </router-link>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
          x="0px" y="0px" width="100px" height="40px" viewBox="0 0 100 40" style="enable-background:new 0 0 100 40;"
          xml:space="preserve">
          <defs></defs>
          <polygon class="arrowNext" points="82.4,4.6 77.2,9.8 83.9,16.4 23.2,16.4 23.2,23.6 83.9,23.6 77.2,30.2 82.4,35.4 92.7,25.1 97.8,20 "/>
          <rect fill="none" stroke="none" width="100" height="40"/>
          </svg>
        </div>
          <hr ref="nextLine">
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import {gsap} from 'gsap';
export default {
data(){
  return{
  }
},
  computed: {
    previous() {
      var prevIndex = this.$parent.id-1
      if (prevIndex >= 0){
      var prev = '/' + this.$root.projects.map(function(e) { return e.name; })[prevIndex]
      }else{
        prev = '/' + this.$root.projects.map(function(e) { return e.name; })[this.$root.projects.length-1]
      }
      return prev
    },
    next() {
      var nextIndex = this.$parent.id+1
      if (nextIndex < this.$root.projects.length){
      var next = '/' + this.$root.projects.map(function(e) { return e.name; })[nextIndex]
      }else{
        next = '/' + this.$root.projects.map(function(e) { return e.name; })[0]
      }
      return next
    }
  },
  methods: {
    mouseoverNext(){
      let nextLine = this.$refs.nextLine;
      gsap.to(nextLine,{ duration:1, width: "100%"})
    },
    mouseoutNext(){
      let nextLine = this.$refs.nextLine;
      gsap.to(nextLine,{ duration:1, width: "0%"})
    }
  }
}
</script>

<style lang='scss' scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) { @content; }
}

.navSection{
    display: flex;
    @include for-phone-only {flex-direction: column;}
    align-items: center;
    width: 100%;
    // padding: 3em 0 3em 0;

}
.prev{
  text-align: right;
  height: 30vh;
  padding: 0 4% 0 0;
  display: none;
  align-items: center;
  justify-content: center;
  & a{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 1;
  }
  & span{
    font-family: 'Lato', helvetica, sans-serif;
    font-weight: 300;
    font-size: 3em;
    margin: 0;
    padding: 1%;
    display: inline-block;
  }
}
.arrowPrev{
  fill: none;
  stroke: #777777;
}
.next{
  font-family: 'Lato', helvetica, sans-serif;
  // width:100%;
  height: 30vh;
  margin: 50px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .nextWrapper{
    display: flex;
    flex-direction: column;
    // width: 40%;
    .nextProject{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-left: solid 1px #777777;
      padding: 0.5em 0 0.5em 4%;
    }
  }
  & a{
    font-size: 4vw;
    font-weight: 300;
    display: block;
  }
  & span{
    width:100%;
    white-space: nowrap;
  }
  hr{
    margin: 0;
    width: 0%;
    height: 1px;
    background: linear-gradient(to right, #FF0000FF, #FF0000DD, #FF000000);
    border: none;
  }
  svg{
    text-align: right;
    margin: 0;
  }
}

.arrowNext{
  fill: none;
  stroke: #777777;
  stroke-width: 1.3;
}


</style>