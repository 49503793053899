<template>
    <div class="content penhaligons">
        <div class="cover">
            <img src="../assets/imgs/Penhaligons/Penhaligons.png"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>Penhaligon's Journey through History</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/Penhaligons/section_1.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/Penhaligons/Still_01.jpg" />
            </div>
            <div class="column">
                <img src="../assets/imgs/Penhaligons/Still_02.jpg" />
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/Penhaligons/section_2.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <img src="../assets/imgs/Penhaligons/Still_03.jpg" />
        </div>
        <projectsNav/>            
    </div><!--end content-->
</template>

<script>
export default {
    name: 'penhaligons',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("penhaligons")
        }
    }

}
</script>

<style scoped>

</style>