<template>
    <div class="content 3D">
        <div class="cover">
            <img src="../assets/imgs/3D/I_Cover.jpg"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>Visual thoughts</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>
        </div>
        <hr/>
        <div class="section I">
            <video class="column IVid" src="../assets/video/3D/False_mirror_Salt_flats.mp4" autoplay loop muted playsinline></video>
            <p class="column IDesc" ref="IDesc">
                Inspired by one of my favourite surrealist painters, René Magritte, in particular, the painting titled false mirror, this project is an exploration of Octane and HDRI lighting.
            </p>
        </div>
        <div class="section heading CST">
            <h1 class="projTitle" ref="ITitle">REFLECT</h1>
        </div>
        <div class="section I">
            <video class="IVid2" src="../assets/video/3D/False_mirror_Salt_flats_2.mp4" autoplay loop muted playsinline></video>
        </div>
        <hr/>
        <div class="section CST">
            <p class="column CSTDesc" ref="CSTDesc">
                Inspired by the Kandinsky's teaching at the Bauhaus, this is an exploration of the fundamental forms <span style="color: yellow">△</span> <span style="color: red">☐</span> <span style="color: blue">◯</span> with a 4 dimentional twist
            </p>
            <video class="column CSTVid" src="../assets/video/3D/C-S-T.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section heading CST">
            <h1 class="projTitle" ref="CSTTitle">TWIST</h1>
        </div>
        <div class="section CST CSTDetails">
            <div class="column CSTFront" ref="CSTFront">
                <img src="../assets/imgs/3D/sculpture_full.jpg" />
            </div>
            <div class="column CSTSide">
                <img src="../assets/imgs/3D/sculpture_Side.jpg" />
            </div>
        </div>
        <div class="section CST">
                <img class="CSTCloseup" src="../assets/imgs/3D/sculpture_closeup.jpg" />
        </div>
        <hr/>
        <div class="section OSC">
            <video class="column projVid" src="../assets/video/3D/final_Gyro_loopable.mp4" autoplay loop muted playsinline></video>
        
                <p class="column OSCDesc" ref="OSCDesc">
                I created this project while studying the gimbal rotation mechanisms in Cinema 4D. Partly inspired by the opening theme of Game of Thrones.
                The animation consists of two layers of motion. The rotation was created purely with C4D’s XPresso, as illustrated in the node tree below, and the oscillation was created with a sine formula deformers. The result is infinitely rotating oscillating rings around a levitating drop of water.
                </p>
        </div>
        <div class="section heading OSC">
            <h1 class="projTitle" ref="OSCTitle">OSCILLATE</h1>
        </div>
        <div class="section OSC">
                <img class="column still1" src="../assets/imgs/3D/still_01.jpg" />
                <video class="column process" ref="OSCProcess" src="../assets/video/3D/gyro_work_process.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section OSC">
            <img src="../assets/imgs/3D/oscillation_node_tree.jpg" />
        </div>
        <div class="section OSC">
            <img src="../assets/imgs/3D/still_02.jpg" />
        </div>
        <hr/>
        <div class="section plexus">
            <p class="column plexDesc" ref="PlexDesc">
                This is a repurposed design of what started as a competition entry for a book cover. The pyramid was made in Cinema 4D, then imported into After Effects, then Plexus was used to create the final look with some chromatic aberration added.
            </p>
            <video class="column plexVid" src="../assets/video/3D/plexus_pyramid.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section heading plexus">
            <h1 class="projTitle" ref="plexTitle">CONNECT</h1>
        </div>
        <hr/>
        <div class="section LS">
            <video class="LSVid" src="../assets/video/3D/Landscape.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section heading LS">
            <h1 class="projTitle" ref="LSTitle">UNPLUG</h1>
        </div>
        <hr/>
        <div class="section MSP">
            <img src="../assets/imgs/3D/The Portrait (Magritte).jpg" />
        </div>
        <projectsNav/>
    </div><!--end content-->
</template>

<script>
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'visualThoughts',
    mounted() {
        setTimeout(() => {
            if(!this.isMobile && window.innerWidth > 950){
                this.parallax();
                this.onResize();
            }
        }, 1000);
    },
    activated(){
            this.parallax();
            this.onResize();
    },
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("visualThoughts")
        }
    },
    methods: {
        onResize(){
            window.addEventListener("resize", () =>{ this.parallaxMapping;})
        },
        parallaxMapping(){
            let min = -40
            let max = -130 
            let minWidth = 900
            let maxWidth = 2000
            let width = window.innerWidth
            return ((width - minWidth)/(maxWidth-minWidth)*(min-max) + max) + "%"
        },
        parallax(){

            const OSCDesc = this.$refs.OSCDesc;
            const OSCTitle = this.$refs.OSCTitle;
            const OSCProcess = this.$refs.OSCProcess;
            const CSTDesc = this.$refs.CSTDesc;
            const CSTTitle = this.$refs.CSTTitle;
            const CSTFront = this.$refs.CSTFront;
            const IDesc = this.$refs.IDesc;
            const ITitle = this.$refs.ITitle;
            const PlexDesc = this.$refs.PlexDesc;
            const plexTitle = this.$refs.plexTitle;
            const LSTitle = this.$refs.LSTitle;

            gsap.to(OSCDesc,    {scrollTrigger: {trigger: OSCDesc, scrub: 1},           y:"-100%"});
            gsap.to(OSCTitle,   {scrollTrigger: {trigger: OSCTitle, scrub: 1},          y:"-80vh"});
            gsap.to(OSCProcess, {scrollTrigger: {trigger: OSCProcess, scrub: 1},        y: this.parallaxMapping()});
            gsap.to(CSTDesc,    {scrollTrigger: {trigger: CSTDesc, scrub: 1},           y:"40vh"});
            gsap.to(CSTTitle,   {scrollTrigger: {trigger: CSTTitle, scrub: 1},          y:"-100vh"});
            gsap.to(CSTFront,   {scrollTrigger: {trigger: CSTFront, scrub: 1},          y:"-50vh"});
            gsap.to(IDesc,      {scrollTrigger: {trigger: IDesc, scrub: 1},             y:"-40vh"});
            gsap.to(ITitle,     {scrollTrigger: {trigger: ITitle, scrub: 1},            y:"-130vh"});
            gsap.to(PlexDesc,   {scrollTrigger: {trigger: PlexDesc, scrub: 1},          y:"-40vh"});
            gsap.to(plexTitle,  {scrollTrigger: {trigger: plexTitle, scrub: 1},         y:"-130vh"});
            gsap.to(LSTitle,    {scrollTrigger: {trigger: LSTitle, scrub: 1},           y:"-130vh"});
        }
    }

}
</script>

<style lang="scss" scoped>
.section{
    width: 90%;
    padding-right: 5%;
    padding-left: 5%;
}


.OSC{
    padding-top: 8vh;
    padding-bottom: 8vh;
    img{
        width: 70%;
        margin: auto;
    }
    .still1{
        margin: 0; 
        width: 50%;
        align-self: flex-start;
    }
    .process{
        margin: 0 0 0 -3%;
        width: 53%;
        align-self: flex-end;
    }
    .column{
        img{
            width: 100%;
        }
        video{
            width: 100%;
        }
    }
    .projVid{
        width: 60%;
    }   
    .OSCDesc{
        color: white;
        width: 40%;
        margin: 0 0 0 -2rem;
        padding: 3rem;
        background-color: #7a0000;
        z-index: 10;
        align-self: flex-end;
    }
}
.heading{
    max-height: 0vh;
    width: 100%;
    padding: 5vh 0 5vh 0;
    .projTitle{
        color: rgb(227, 227, 227);
        mix-blend-mode: exclusion;
        font-size: 18vw;
        margin: 0 auto;
    }
}




.CST{
    padding: 8vh 5% 8vh 5%;
    width: 90%;
    justify-content: flex-end;

    .CSTVid{
        max-height: 90vh;
        max-width: 60%;
        width: auto;
        margin: 0 0 0 -2rem;
    }
    .CSTDesc{
        color: white;
        width: 40%;
        // margin: 0 -4rem 0 10%;
        padding: 3rem;
        background-color: #7a0000;
        z-index: 10;
        align-self: flex-start;
    }
    .CSTFront{
        width: 50%;
        margin: 0;
    }
    .CSTSide{
        align-self: flex-end;
        width: 40%;
        margin: 0;
    }
    .CSTCloseup{
        max-height: 80vh;
        width: auto;
        margin: auto;
    }
}
.CSTDetails{
    justify-content: space-between;
}




.I{
    padding-top: 8vh;
    padding-bottom: 8vh;
    justify-content: start;
    .IVid{
        max-height: 80vh;
        // max-width: 60%;
    }
    .IDesc{
        color: white;
        width: 40%;
        margin: 0 0 0 -2rem ;
        padding: 3rem;
        background-color: #7a0000;
        z-index: 10;
        align-self: flex-end;
    }
    .IVid2{
        max-height: 80vh;
        margin: auto;
    }
}


.plexus{
    padding-top: 8vh;
    padding-bottom: 8vh;
    justify-content: flex-end;
    .plexvid{
        margin: 0;
    }
    .plexDesc{
        color: white;
        width: 40%;
        margin: 0 ;
        padding: 3rem;
        background-color: #7a0000;
        z-index: 10;
        align-self: flex-end;        
    }
    .plexVid{
        max-height: 90vh;
        max-width: 60%;
        width: auto;
        margin: 0 0 0 -2rem;
    }

}


.LSVid{
width: 80%;
margin: auto;
}


.MSP{
    img{
        max-height: 80vh;
        width: auto;
        margin: auto;
    }
}

hr{
    width: 100%;
    height: 1px;
    background: #000;
    // background-image: linear-gradient(to right, #ccc, #333, #ccc);
    border: none;
    margin: 50px 0 50px 0;
}

</style>