<template>
    <div class="content FA">
        <div class="cover">
            <img src="../assets/imgs/FA/HalitYozgat/FA_NSU.png"/>
        </div>
        <div class="section description">
            <div class="column title">
                <h2>The Murder Of Halit Yozgat</h2>
            </div>
            <div class="column projText">
                <p v-html="$root.projects[this.id].description"> </p>
            </div>
        </div>
        <div class="section">
            <video src="../assets/video/FA/2_screens_excerpt_1.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <video src="../assets/video/FA/Temme_tl.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <video src="../assets/video/FA/enactment_timer.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="section">
            <img src="../assets/imgs/FA/HalitYozgat/still2.png">
        </div>
        <div class="section">
            <div class="column">
                <img src="../assets/imgs/FA/HalitYozgat/ICA_CounterInvestigation_1.jpg">
                <img src="../assets/imgs/FA/HalitYozgat/ICA_CounterInvestigation_2.jpg">
            </div>
            <div class="column">
                <img src="../assets/imgs/FA/HalitYozgat/ICA_CounterInvestigation_3.jpg">
            </div>
        </div>
        <projectsNav/>
    </div><!--end content-->
</template>

<script>
export default {
    name: 'FA',
    data() {
        return{
            id: this.$root.projects.map(function(e) { return e.name; }).indexOf("FAHalitYozgat")
        }
    },
    props: [
    ],

}
</script>

<style lang="scss" scoped>

.section{
    > img{
        width: calc(100% - 1.5rem);
        padding: 0.75rem;
    }
}

.column{
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img{
        margin: 0.75rem 0 0.75rem 0;
    }
}

</style>