import Vue from 'vue';
import router from './router';
// import jQuery from 'jquery';
// import $ from 'jquery';
import App from './App.vue';
import {gsap} from 'gsap';
import projectsNav from './components/ProjectsNav.vue';
import FAHalitYozgat from './views/FAHalitYozgat';
import FAHarithAugustus from './views/FAHarithAugustus';
import EIKON from './views/EIKON';
import penhaligons from './views/penhaligons';
import visualThoughts from './views/visualThoughts';
import chess from './views/chess';
import farfetch from './views/farfetch';
import bauhaus from './views/bauhaus';
import projectsList from './components/projectsList';
import VueAnalytics from 'vue-analytics'

global.gsap = gsap
Vue.component('projectsNav', projectsNav)
Vue.component('projectsList', projectsList)

Vue.config.productionTip = false;


Vue.use(VueAnalytics, {
  id: 'UA-52761043-1',
  router
})


// export const bus = new Vue();





Vue.mixin({
  computed: {
    isMobile() {
      if(navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
        return true;
      }else {
        return false;
      }
    }
  }
})

new Vue({
  router,
  data: {
    projects: [ 
      {
      name: "FAHarithAugustus",
      component: FAHarithAugustus,
      title: "FA/Harith Augustus",
      img: require('./assets/imgs/FA/HarithAugustus/The_Killing_of_Harith_Augustus_milliseconds_6.jpg'),
      category: "Editing/motion graphics",
      description: `Forensic Architecture is a multidisciplinary, multi-award winning research agency based in Goldsmiths, University of London. they conduct investigations into state violence, human rights violations. I worked with the amazing team of investigators, designers, writers and video editors on several projects.<br> This project was an investigation into the killing of Harith Augustus by a Chicago police officer. The investigation delves deep into the incident and the related subsequent events, raising questions about race and policing in the United States<br> The investigation analyses the incident in five different timescales: milliseconds, seconds, minutes, hours and days using several methods; spatial mapping, documentation and interviews<br> I contributed to parts of the project by editing footage and documents, highlighting key information and binding these to a designed timeline, which serves as a signpost for key events. To view the full project click <a href="https://forensic-architecture.org/investigation/the-killing-of-harith-augustus" style="display: inline; color: red"> here </a>`
    }, 
    {
      name: "FAHalitYozgat",
      component: FAHalitYozgat,
      title: "FA/Halit Yozgat",
      img: require('./assets/imgs/FA/HalitYozgat/FA_NSU.png'),
      category: "motion graphics",
      description: `Forensic Architecture is a multidisciplinary, multi-award winning research agency based in Goldsmiths, University of London. they conduct investigations into state violence, human rights violations. I had the pleasure to work with them on several projects.<br>
      This project was an investigation into the testimony of the intelligence officer Andres Temme in relation to the murder of Halit Yozgat by the neo-Nazi group National Socialist Underground (NSU), 6 April 2006.<br>
      The investigation team undertook spatial and temporal analysis of the crime scene using several research methods, in addition to the analysis of hundreds of documents leaked from the police investigation. The findings are presented in a 29 minutes triptych video.<br>
      I contributed to the project by designing and animate the timeline, which visualises the timing of the activities of the key witnesses in the internet cafe where the crime took place. to view the full project click <a href="https://forensic-architecture.org/investigation/the-murder-of-halit-yozgat" style="display: inline; color: red"> here </a>`
    },
    {
      name: "visualThoughts",
      component: visualThoughts,
      title: "Visual Thoughts",
      img: require('./assets/imgs/3D/I_Cover.jpg'),
      category: "3D design",
      description: `On this page, I showcase some of the 3D visual thoughts and experiments I create using Cinema 4D and Octane Render with Some. These are personal endeavours, not commercial projects.`
    }, 
    {
      name: "chess",
      component: chess,
      title: "Chess Maps",
      img: require('./assets/imgs/chessMaps/cover.png'),
      category: "Graphic Design",
      description: `Marcel Duchamp once said "while all artists are not chess players, all chess players are artists." A chess game can be considered a piece of art "A chess game is very plastic. You construct it. One creates beautiful problems and that beauty is made with the head and hands." <br>
      I was inpired to create this design concept while studying some chess games, particularly, the game between Garry Kasparov and the IBM chess computer Deep Blue`
    }, 
    {
      name: "penhaligons",
      component: penhaligons,
      title: "Penhaligon's",
      img: require('./assets/imgs/Penhaligons/Penhaligons.png'),
      category: "motion graphics",
      description: `This promo video was Created to celebrate the rich history of the Penhaligon’s brand, 
      a fun journey through the world of Penhaligon’s fragrances.
      I worked on animating parts of this video with the talented team at Just So.`
    },
    {
      name:"EIKON",
      component: EIKON,
      title: "EIKON",
      img: require('./assets/imgs/EIKON/REUTERS_EIKON.png'),
      category: "motion graphics",
      description: `This project promotes the Thompson Reuters EIKON app to intraday power traders in Europe. The video was designed and animated in collaboration with the talented motion graphic designer Valentino Laz.`
    },
    {
      name: "farfetch",
      component: farfetch,
      title: "Game on Japan",
      img: require('./assets/imgs/Farfetch/Game_on_Japan.png'),
      category: "motion graphics/compositing",
      description: `An interactive video made for the fashion brand Farfetch To celebrate the launch of their branch in Japan. I worked with the talented team at Just So in 2014 on the keying, roto and compositing of this project.
      project links <a href= "https://www.farfetch.com/uk/editorial/game-on-japan.aspx"> here </a>`
    }, 
    {
      name: "bauhaus",
      component: bauhaus,
      title: "Bauhaus/Vkhutemas",
      img: require('./assets/imgs/Bauhaus/Bauhaus5825.jpg'),
      category: "graphic design",
      description: `An essay about the relations between the German Art school, the Bauhaus and its Russian counterpart, the Vkhutemas against the backdrop of the propagation of modernism during the political and social unrest of the early twentieth century.`
    }]
},
  render: h => h(App)
}).$mount('#app');
