<template>
<div id="listWrapper">
    <ul id="projectsList">
        <li ref="items" v-bind:key="project.id" v-for="project in $root.projects"><router-link :to="project" @click.native="hideList">{{project.title.toLowerCase()}}</router-link></li>
    </ul>
    <div id="projImg" ref="img"></div>
</div>
</template>

<script>
import {gsap} from 'gsap';
export default {
    name: 'projectsList',
    data(){
        return{
            backgrounds: {
                FAHarithAugustus: {
                    id: 0,
                    BG: this.$root.projects[0].img
                },
                FAHalitYozgat: {
                    id: 1,
                    BG: this.$root.projects[1].img
                },
                visualThoughts: {
                    id: 2,
                    BG: this.$root.projects[2].img
                },
                ChessMaps: {
                    id: 3,
                    BG: this.$root.projects[3].img
                },
                penhaligons: {
                    id: 4,
                    BG: this.$root.projects[4].img
                },
                EIKON: {
                    id: 5,
                    BG: this.$root.projects[5].img
                },
                farfetch: {
                    id: 6,
                    BG: this.$root.projects[6].img
                },
                bauhaus: {
                    id: 6,
                    BG: this.$root.projects[7].img
                },
            }
        }
    },
    mounted(){
        this.background()
    },
    methods: {
        background(){
            let list = this.$refs.items
            for(let i=0; i<list.length; i++){
                list[i].addEventListener('mouseover', ()=>{
                    const BGarray = Object.entries(this.backgrounds)
                    const BG = "url("+BGarray[i][1].BG+")"
                    let projImg = document.getElementById('projImg')
                    gsap.set(projImg, {autoAlpha: 0})
                    projImg.style.backgroundImage = BG
                    gsap.to(projImg, {duration: 0.5, autoAlpha: 1})
                })
                list[i].addEventListener('mouseout', function(){
                    let projImg = document.getElementById('projImg')
                    gsap.to(projImg, {duration: 0.5, autoAlpha: 0})
                    
                })
            }
        },
        hideList(){
            this.$emit('hideProjectList')
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

#listWrapper{
    font-family: 'Lato', helvetica, sans-serif;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #2b2b2b;
    z-index: 10;
    #projectsList{
        width: 25%;
        height: 75%;
        text-align: center;
        padding: 0;
        margin:0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-size: cover;
        background-position: center;
        @include for-phone-only {
            text-align: center;
        }
        li{
            width: 95%;
            padding: 0 0 0 0;
            list-style: none;
            display: block;
            line-height: 3rem;
            background-size: cover;
            background-position: center;
            @include for-phone-only {
                line-height: 4.5em;
                padding: 0;
            }
            a{
                color: aliceblue;
                font-size: 1rem;
                display: block;
            }
        }
    }
    #projImg{
        width: 75%;
        height: 75%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
    
</style>